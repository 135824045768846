/**
 * Enable smooth scrolling with an offset.
 */
document.querySelectorAll('.smoothscroll').forEach((element) => {
  element.onclick = (e) => {
    e.preventDefault();

    const scrollTarget = document.getElementById(
      e.target.hash.replace('#', ''),
    );

    window.scrollTo({
      top: scrollTarget.offsetTop - 0, // TODO: Determine how to allow a custom offset
      behavior: 'smooth',
    });
  };
});
